import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components'
import "./Assets/styles/common.scss"
import Button from '@mui/material/Button';  

import GiftBox from './Assets/images/giftbox.png';
import CU1000 from './Assets/images/cu1000.png';
import EM1000 from './Assets/images/em1000.png';
import SE1000 from './Assets/images/se1000.png';
import GS1000 from './Assets/images/gs1000.png';
import PlayerBackground from './Assets/images/player_background.png';
import PlayerPlayButton from './Assets/images/player_play_button.png';
import OpenGiftBox from './Assets/images/opengiftbox.png';
import { getFirestore,  doc, getDoc, updateDoc, increment } from 'firebase/firestore/lite';

import ReactAudioPlayer from 'react-audio-player';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import { Backdrop, Modal, Slide, ThemeProvider, createTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Initialize Firebase
const app = initializeApp({
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
});
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);

const theme = createTheme({
    palette: {
        primary: {
            main: "#00A1E7" 
        },
    }
});
interface TextProps {
    size?: number;
    bold?: boolean;
    color?: string;
}
const Text = styled.a<TextProps>`
    font-size: ${({size}) => size || 12}px;
    font-weight: ${({bold}) => bold ? "bold" : "normal"};
    color: ${({color}) => color || "var(--black1)"};
`
const TextLine = styled.span`
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 1545px;
    
`
const Banner = styled.div`
    width: auto;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #EFFAFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const BannerText = styled.span`
    
`
interface MainContainerProps {
    visible?: boolean;
}
const MainContainer = styled.div<MainContainerProps>`
    transition: opacity 0.5s;
    opacity: ${({ visible }) => (visible ? 1 : 0)}; // 상태에 따라 opacity 조절
    width: auto;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 40px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 10px solid #E4EDF1;
    position: relative;
    overflow: hidden; /* 화면을 넘어가는 내용을 숨깁니다. */
    
`
const GiftBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    row-gap: 24px;
    position: relative;
`
const GiftBoxImage = styled.img`
    width: 200px;
    height: 200px;
    padding-top: 98px;

`
const GiftBoxTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;
    margin-bottom: -12px;
`
const OpenGiftBoxImage = styled.img`
    width: 307px;
    height: 307px;
    padding-top: 84px;
`
const OpenGiftImage = styled.img`
    width: 160px;
    height: 160px;
    border-radius: 10px;
    position: absolute;
    top: 24px;
`
const NoteContainer = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
`
const BulletList = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 20px; /* 들여쓰기를 위한 왼쪽 패딩 추가 */
    row-gap: 10px;
`;

const BulletListItem = styled.li`
    list-style-type: disc; /* 불릿 스타일 지정 */
`;

const MyButton = styled(Button)`
    width: 100%;
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #00a1e7;
    border-radius: 10px;
    text-align: center;
    border-width: 0px;
    &:focus {
        background-color: #00a1e7; /* 포커스 시 색상을 변경합니다. */
        /* 다른 스타일도 필요하다면 여기에 추가합니다. */
    }
    &:hover {
        background-color: #00a1e7; /* 포커스 시 색상을 변경합니다. */
        /* 다른 스타일도 필요하다면 여기에 추가합니다. */
    }
`;

const GiftContainer = styled.div`
    display: flex;
    column-gap: 6px;
    position: absolute;
    margin-top: 36px;
    width: calc(106px * 6);
    
`
interface GiftImageProp {
    opening?: boolean;
}

const GiftImage = styled.img<GiftImageProp>`
    width: 100px; 
    height: 100px;
    animation: ${({opening}) => opening ? `moveImages 1000ms linear infinite`: `moveImages 12000ms linear infinite`};
    border-radius: 10px;
    @keyframes moveImages {
        0% { transform: translateX(0); } /* 시작 위치 */
        100% { transform: translateX(calc(-106px * 4)); } /* 오른쪽에서 왼쪽으로 이동 */
    }
    @keyframes moveImages2 {
        0% { transform: translateX(0); } /* 시작 위치 */
        100% { transform: translateX(calc(-106px * 4)); } /* 오른쪽에서 왼쪽으로 이동 */
    }
`
const CourseSelectorContainer = styled.div`
    display: flex;
    padding-top: 20px;
`
const PlayerContainer = styled.div`
    width: calc(100% + 32px);
    height: 341px;
    display: flex;
    margin-left: -16px;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: url(${PlayerBackground});
    background-size: 100% 100%; */
    position: relative;
    overflow: hidden; /* 내부 이미지가 컨테이너를 넘어갈 경우를 대비하여 오버플로우 숨김 */
`
const PlayerBackgroundImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const PlayerControllerContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 341px;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
`
const PlayerContentsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
`
const CourseContainer = styled.div`
    position: absolute;
    left: 16px;
    bottom: 16px;
    row-gap: 7px;
    display: flex;
    flex-direction: column;
`
const PlayButton = styled(Button)`
    width: 80px;
    height: 80px;
    border-radius: 40px;
`
const PlayButtonImage = styled.img`
    width: 80px;
    height: 80px;
`

const CenterAlignContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const LeftAlignContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const RecvGiftDescContainer = styled.div`
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const OpenBoxContainer = styled.div`
    margin-top: 33px;
    row-gap: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ModalContainer = styled.div`
    position: absolute;
    width: 100%;
    max-width: 480px;
    background-color: var(--white1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`
const ModalTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid var(--gray2);
`
const ModalContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 26px;
    padding-left: 16px;
    padding-right: 16px;
    row-gap: 24px;
`
const ModalItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    flex: 1;
`
const ModalButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 46px;
    align-items: center;
`
const CloseButton = styled.a`
    cursor: pointer;
`
const gift_images = [
    CU1000, EM1000, SE1000, GS1000
]
const gifts = [
    {
        num: 0,
        name: "CU 1,000원 상품권",
        cash: 1200,
        image: CU1000,
    },
    {
        num: 1,
        name: "이마트24 1,000원 상품권",
        cash: 1200,
        image: EM1000
    },
    {
        num: 2,
        name: "세븐일레븐 1,000원 상품권",
        cash: 1200,
        image: SE1000,
    },
    {
        num: 3,
        name: "GS25 1,000원 상품권",
        cash: 1200,
        image: GS1000
    },
]
const courses = [
    {
        index: 5,
        sid: "8uIHmUZlhSEI4b44Gwe94",
        title: "영어로 인사 나누기",
        kor: "좋은 하루 보내세요!",
        eng: "Have a nice day!",
        pro: "해브 어 나이스 데이!",
    },
    {
        index: 2,
        sid: "ekSzdHIPvh0g01daX5x21",
        title: "[항공기] 항공기 탑승",
        kor: "탑승 시간 언제에요?",
        eng: "What's the boading time?",
        pro: "왓츠 더 보딩 타임?",
    },
    {
        index: 5,
        sid: "895ESeJtAzsrPj76xfKq4",
        title: "I'm sure ~ (~ 라고 확신해)",
        kor: "그녀가 널 사랑한다고 확신해.",
        eng: "I'm sure she loves you.",
        pro: "암 슈어 쉬 러브스 유.",
    }
]
type AudiosType = {
    kor_female: string,
    eng_female: string
    eng_male: string,
}
const getRandomNumber = () => {
    // 1부터 100까지의 랜덤 숫자 생성
    let randomValue = Math.floor(Math.random() * 100) + 1;
    if (randomValue <= 10) {
        return 0;  
    } else if (randomValue <= 60) {
        return 1;  
    } else if (randomValue <= 100) {
        return 2;
    } else { 
        return 3;
    }
}
const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const getRandomNickname = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789친짱행가개흥에스오비김이박강구윤모홍신검카어타로벅민나캐똑캐동게매일영요만볼안하세종대왕선대원군";
    let nickname = "";
    let randomLength = Math.floor(Math.random() * 9) + 2;
    const randomIndex = Math.floor(Math.random() * characters.length);
    nickname += characters[randomIndex];

    for (let i = 1; i < randomLength; i++) {
      nickname += "*"
    }
    return nickname;
}
function App() {
    const current = decodeURI(window.location.href);
    const search = current.split("?")[1];
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const channel = params.get('channel');
    const [page, setPage] = useState<number>((channel === "cw" || channel === "ydqz" || channel === "sr") ? 2 : 0);
    const [selectedCourse, setSelectedCourse] = useState<number>(0);   
    const [playing, setPlaying] = useState<boolean>(false);
    const [played, setPlayed] = useState<boolean>(false);
    const [audios, setAudios] = useState<AudiosType | null>(null);
    const [curAudio, setCurAudio] = useState<string>("");
    const [opening, setOpening] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const [visible2, setVisible2] = useState<boolean>(true);
    const [remainingTime, setRemainingTime] = useState<number>(100);
    const [randomNumber, ] = useState(() => {
        const savedNumber = localStorage.getItem('number');
        return Math.min(savedNumber !== null ? JSON.parse(savedNumber) : getRandomNumber(), 3);
    });
    const [bannerInfo, setBannerInfo] = useState({
        name: "S******",
        gift: gifts[getRandomNumber()].name
    })
    const [totalOpened, setTotalOpened] = useState<number>(0);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    useEffect(() => {
        localStorage.setItem('number', JSON.stringify(randomNumber));
    }, [randomNumber]);

    const checkApple = () => {
        logEvent(analytics, 'on_press_lucky_box_apple_check_btn', {
            channel: channel
        });
        const mobileType = navigator.userAgent.toLowerCase();
        if (mobileType.indexOf('iphone') > -1 || mobileType.indexOf('ipad') > -1 || mobileType.indexOf('ipod') > -1) {
            return true;
        }
        return false; 
    };
    const handleOpenModal = () => {
        setModalVisible(true);
    }
    const handleCloseModal = () => {
        setModalVisible(false);
    }
    const loadTotalOpened = async () => {
        try {
            const countRef = doc(firestore, 'luckyBox/count');
            const snapshot = await getDoc(countRef)
            if(snapshot.exists()) {
                const data = snapshot.data();
                if(data?.total) {
                    setTotalOpened(data.total);
                }
            }
        } catch(err) {
            console.log(err);
        }
        
    }
    useEffect(() => {
        loadTotalOpened();
        let interval = setInterval(() => {
            setBannerInfo({
                name: getRandomNickname(),
                gift: gifts[getRandomNumber()].name
            })
        }, 3000);
        return () => {
            if(interval) {
                clearInterval(interval);
            }
        }
    }, [])
    useEffect(() => {
        if(!visible) {
            setTimeout(() => {
                setPage(3);
                setRemainingTime(100);
                setInterval(() => {
                    setRemainingTime((prev) => prev > 0 ? prev - 1 : 0);
                }, 1000)
            }, 1000)
        }
    }, [visible])
    useEffect(() => {
        if(!visible2) {
            setTimeout(() => {
                setPage(2);
            }, 1000)
        }
    }, [visible2])
    useEffect(() => {
        if(opening) {
            setTimeout(() => {
                setVisible(false)
            }, 2000)
        }
    }, [opening])
    useEffect(() => {
        if(played) {
            setVisible2(false)
        }
    }, [played])
    const changeCourse = (index: number) => {
        if(selectedCourse === index) return;
        if(played || playing) return;
        setSelectedCourse(index);
        setPlaying(false);
        setCurAudio("");
        setAudios(null);
    } 
    
    
    
    useEffect(() => {
        if(audios) {
            setCurAudio(audios.kor_female);
        }
    }, [audios])
    const play = () => {
        const {sid, kor, eng} = courses[selectedCourse];
        logEvent(analytics, 'on_press_lucky_box_listen_btn', {
            channel: channel
        });
        tts(sid, kor, eng).then((result) => {
            setAudios(result);
            setPlaying(true);
        });
    }
    const tts = async (sid: string, kor: string, eng: string): Promise<any> => {
        const url = "https://mck4hvafqhb2pa6le5p2mwqy3q0bpkvi.lambda-url.ap-northeast-2.on.aws/tts"
        try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ sid, kor, eng }),
            });
            if (response.ok) {
              const data = await response.json();
              const {eng_female, eng_male, kor_female, kor_male} = data;
              return {eng_female, eng_male, kor_female, kor_male}
            } else {
              console.error('Error:', response.status, response.statusText);
              return null;
            }
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    const openBox = () => {
        setOpening(true);
        logEvent(analytics, 'on_press_lucky_box_open_btn', {
            channel: channel
        });
        const countRef = doc(firestore, 'luckyBox/count');
        updateDoc(countRef, {
            total: increment(1),
        })
    }
    const goApp = () => {
        logEvent(analytics, 'on_press_lucky_box_store_btn', {
            channel: channel
        });
        let trackingLink = 'https://abr.ge/3xrlxc';
        if(channel) {
            switch(channel) {
                case "ga":
                    trackingLink = 'https://abr.ge/cabgcu';
                    break;
                case "cw":
                    trackingLink = 'https://abr.ge/na3n47k';
                    break;
                case "gt":
                    trackingLink = 'https://abr.ge/vbk38t';
                    break;
                case "ydqz":
                    trackingLink = 'https://abr.ge/crirep';
                    break;
                case "kakao":
                    trackingLink = 'https://abr.ge/ev9kwz';
                    break;
                case "sr":
                    trackingLink = 'https://abr.ge/xrnxnq';
                    break;
                default:
                    trackingLink = 'https://abr.ge/3xrlxc';
                    break;
            }
        }
        if(code) {
            window.open(`${trackingLink}?deeplink_url=luckybox${randomNumber}?code=${code}`);
        } else{
            window.alert("초대 코드가 조회되지 않습니다. 초대 링크를 통해 다시 시도해주세요!")
        }
    }
    const getChannelSpecificString = (channel: string | null) => {
        if(channel === 'cw') {
            return '[캐시워크 유저 전용]'
        } 
        else if(channel === 'ydqz') {
            return '[용돈퀴즈 전용]'
        }
        else {
            return ""
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Modal
                style={{
                    width: '100%',
                    height: '100%',
                    alignItems:'center',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column'
                }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalVisible}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                    }}
                >
                    <ModalContainer>
                        <ModalTitleContainer>
                            <Text size={24} bold>
                                애플 기기 회원님이신가요?
                            </Text>
                            <CloseButton onClick={handleCloseModal}>
                                <CloseIcon style={{
                                    width: 24,
                                    height: 24
                                }}/>
                            </CloseButton>
                        </ModalTitleContainer>
                        <ModalContentsContainer>
                            <ModalItemContainer>
                                <CheckCircleIcon
                                color="primary"
                                style={{
                                    width: 36,
                                    height: 36
                                }}
                                />
                                <Text bold size={14}>
                                당첨 선물을 받기 위해서는 “앱 추적 허용" 권한이 반드시 필요해요.
                                </Text>
                            </ModalItemContainer>
                            <ModalItemContainer>
                                <CheckCircleIcon
                                color="primary"
                                style={{
                                    width: 36,
                                    height: 36
                                }}
                                />
                                <Text bold size={14}>
                                어떤 선물이 당첨됐는지 알기 위해 필요한 권한이라 꼭 허용을 눌러주세요.
                                </Text>
                            </ModalItemContainer>
                        </ModalContentsContainer>
                        <ModalButtonContainer>
                            <Text bold size={14}>
                                스픽캐시 앱에서 바로 당첨 선물을 받으세요!
                            </Text>
                            <MyButton id="btn_start" onClick={goApp}>
                                <Text bold size={20} color="var(--white1)">
                                    당첨 선물 받으러 가기
                                </Text>
                            </MyButton>
                        </ModalButtonContainer>
                    </ModalContainer>
                </Modal>
                <Banner>
                    <BannerText>
                        <Text bold>
                            {bannerInfo.name}
                        </Text>
                        <Text>
                            {" 님이 방금 "}
                        </Text>
                        <Text bold>
                            {bannerInfo.gift}
                        </Text>
                        <Text>
                            {" 당첨!"}
                        </Text>    
                    </BannerText>
                </Banner>
                {page === 0 &&
                <MainContainer visible>
                    <Text style={{paddingBottom: 4}} bold size={20}>
                        친구도 나도 1,000원 상품권 {(channel === 'cw' || channel === 'ydqz') && `[${getChannelSpecificString(channel)}]`}
                    </Text>
                    <div>
                        <Text bold size={14}>
                            {"행운 상자 연 사람 : "}
                        </Text>
                        <Text bold size={14} color="var(--blue1)">
                            {totalOpened.toLocaleString("ko-kr")}명
                        </Text>
                    </div>
                    <GiftBoxContainer>
                        <GiftBoxImage src={GiftBox}/>
                        <Text bold size={20}>
                            이벤트가 종료되었습니다.
                        </Text>
                        {/* <Text bold size={20}>
                            100% 기프티콘 당첨 상자
                        </Text> */}
                        <GiftContainer>
                        {gift_images.map((image, index) => (
                            <GiftImage key={index} src={image} alt={`Image ${index}`}/>
                        ))}
                        {gift_images.map((image, index) => (
                            <GiftImage key={index} src={image} alt={`Image ${index + 6}`}/>
                        ))}
                        </GiftContainer>
                    </GiftBoxContainer>
                    {/* <MyButton id="btn_start" onClick={() => {
                        logEvent(analytics, 'on_press_lucky_box_main_btn', {
                            channel: channel
                        });
                        setPage(1);
                        }}>
                        <Text bold size={20} color="var(--white1)">
                            행운 상자 열기
                        </Text>
                    </MyButton> */}
                </MainContainer>
                }
                {page === 1 &&
                <MainContainer visible={visible2}>
                    <Text style={{paddingBottom: 4}} bold size={20}>
                        영어를 들으면 기프티콘을 드려요.
                    </Text>
                    <Text bold size={14} color="var(--blue1)">
                        #영어회화 #스픽캐시 #기프티콘
                    </Text>
                    <CourseSelectorContainer>
                        <Button variant="text" onClick={() => changeCourse(0)}>
                            <Text bold={selectedCourse===0} size={16} color={selectedCourse===0 ? 'var(--black1)' : 'var(--black3)'} style={{
                                textDecoration: selectedCourse===0 ? 'underline' : 'none',
                            }}>
                                👋 생활영어
                            </Text>
                        </Button>
                        <Button variant="text" onClick={() => changeCourse(1)}>
                            <Text bold={selectedCourse===1} size={16} color={selectedCourse===1 ? 'var(--black1)' : 'var(--black3)'} style={{
                                textDecoration: selectedCourse===1 ? 'underline' : 'none',
                            }}>
                                ✈️ 여행영어
                            </Text>
                        </Button>
                        <Button variant="text" onClick={() => changeCourse(2)}>
                            <Text bold={selectedCourse===2} size={16} color={selectedCourse===2 ? 'var(--black1)' : 'var(--black3)'} style={{
                                textDecoration: selectedCourse===2 ? 'underline' : 'none',
                                cursor: 'pointer'
                            }}>
                                🔤 패턴영어
                            </Text>
                        </Button>
                    </CourseSelectorContainer>
                    <PlayerContainer>
                        <PlayerBackgroundImage src={PlayerBackground}/>
                        <PlayerContentsContainer>
                            <Text color='var(--white1)' size={16} style={{
                                textAlign: "center"
                            }}>
                                {courses[selectedCourse].kor}
                            </Text>    
                            <Text color='var(--blue1)' size={36} style={{
                                textAlign: "center"
                            }}>
                                {courses[selectedCourse].eng}
                            </Text>    
                            <Text color='#9DD9F2' size={16} style={{
                                textAlign: "center"
                            }}>
                                {courses[selectedCourse].pro}
                            </Text>    
                        </PlayerContentsContainer>
                        <CourseContainer>
                            <Text bold size={16} color='var(--white1)'>
                                {courses[selectedCourse].title}
                            </Text>
                            <Text bold size={14} color='var(--white1)'>
                                {`${courses[selectedCourse].index} / 16`}
                            </Text>
                        </CourseContainer>
                        {!playing ?
                        <PlayerControllerContainer>
                            <PlayButton onClick={play}>
                                <PlayButtonImage src={PlayerPlayButton}/>
                            </PlayButton>
                        </PlayerControllerContainer>
                        :
                        curAudio &&
                        <ReactAudioPlayer
                            autoPlay
                            src={curAudio}
                            onEnded={(e) => {
                                if(curAudio === audios?.kor_female) {
                                    setCurAudio(audios.eng_male)
                                }
                                else if(curAudio === audios?.eng_male) {
                                    setCurAudio(audios.eng_female)
                                }
                                else if(curAudio === audios?.eng_female) {
                                    setCurAudio("")
                                    setPlayed(true);
                                }
                            }}
                        />
                        }
                    </PlayerContainer>
                    {!playing &&
                    <CenterAlignContainer>
                        <Text bold size={16} color="var(--blue1)">
                            가운데 재생 버튼을 클릭하세요.
                        </Text>
                        <Text bold size={16} color="var(--black3)">
                            *소리가 나와요. 볼륨 조절!
                        </Text>
                    </CenterAlignContainer>
                    }
                    {playing&&
                    <OpenBoxContainer>
                        <div>
                            <Text bold size={16} color="var(--black1)">
                                영어 표현을 다 들으면 행운 상자를 받아요.
                            </Text>
                        </div>
                    </OpenBoxContainer>
                    }
                </MainContainer>
                }
                {page === 2 &&
                <MainContainer  visible={visible}>
                    <Text style={{paddingBottom: 4}} bold size={20}>
                        친구도 나도 1,000원 상품권 {(channel === 'cw' || channel === 'ydqz') && `[${getChannelSpecificString(channel)}]`}
                    </Text>
                    <div>
                        <Text bold size={14}>
                            {"행운 상자 연 사람 : "}
                        </Text>
                        <Text bold size={14} color="var(--blue1)">
                            {totalOpened.toLocaleString("ko-kr")}명
                        </Text>
                    </div>
                    <GiftBoxContainer>
                        <GiftBoxImage src={GiftBox}/>
                        <GiftBoxTextContainer>
                        <Text bold size={20}>
                            100% 기프티콘 당첨 상자
                        </Text>
                        {(channel === 'cw' || channel === 'ydqz') &&
                        <Text bold size={14} color="#FF8A00">
                            {getChannelSpecificString(channel)} - 500캐시 제외
                        </Text>
                        }
                        </GiftBoxTextContainer>
                        
                        <GiftContainer>
                        {gift_images.map((image, index) => (
                            <GiftImage key={index} src={image} alt={`Image ${index}`} opening={opening}/>
                        ))}
                        {gift_images.map((image, index) => (
                            <GiftImage key={index} src={image} alt={`Image ${index + 6}`} opening={opening}/>
                        ))}
                        </GiftContainer>
                    </GiftBoxContainer>
                    {!opening &&
                    <MyButton onClick={openBox} >
                        <Text bold size={20} color="var(--white1)">
                            행운 상자 열기
                        </Text>
                    </MyButton>
                    }
                </MainContainer>
                }
                {page === 3 &&
                <MainContainer  visible>
                    <LeftAlignContainer>
                        <Text bold size={20}>
                            {gifts[randomNumber].name} 당첨!
                        </Text>
                        <Text bold size={20}>
                            지금 스픽캐시에서
                        </Text>
                        <Text bold size={20} color="var(--blue1)">
                            당첨된 선물을 받으세요!
                        </Text>
                    </LeftAlignContainer>
                    <GiftBoxContainer>
                        <OpenGiftBoxImage src={OpenGiftBox}/>
                        <Text bold size={14} style={{
                            marginTop: 17,
                            marginBottom: 12,
                            position: 'absolute',
                            top: 345,
                        }}>
                            당첨 선물은 {remainingTime}초 후에 사라져요. 지금 받으세요!
                        </Text>
                        <OpenGiftImage src={gifts[randomNumber].image}/>
                    </GiftBoxContainer>
                    <MyButton onClick={() => {
                        if(checkApple()) {
                            handleOpenModal();
                        }
                        else {
                            goApp()
                        }
                        }}>
                        <Text bold size={20} color="var(--white1)">
                            지금 바로 당첨 선물 받기
                        </Text>
                    </MyButton>
                    <RecvGiftDescContainer>
                        <TextLine>
                            <Text color="var(--black2)">
                            *스픽캐시 가입 완료시, {gifts[randomNumber].name}을 구매할 수 있는
                            </Text>
                            <Text color="#FF8A00" bold>
                            {` ${gifts[randomNumber].cash.toLocaleString('ko-kr')}캐시`}
                            </Text>
                            <Text color="var(--black2)">
                            를 즉시 지급해 드립니다.
                            </Text>
                        </TextLine>
                    </RecvGiftDescContainer>
                </MainContainer>
                }
                <NoteContainer>
                    <Text bold size={16} style={{marginTop: 16}}>
                        스픽캐시 - 돈 버는 라디오
                    </Text>
                    <BulletList>
                        <BulletListItem>
                            <TextLine>
                                <Text size={16}>
                                다른 앱 쓰면서 돈 벌 수 있는
                                </Text>
                                <Text size={16} bold>
                                {" 백그라운드재생기능 "}
                                </Text>
                                <Text size={16}>
                                업데이트
                                </Text>
                            </TextLine>
                        </BulletListItem>
                        <BulletListItem>
                            <TextLine>
                                <Text size={16}>
                                음악처럼 틀어두면 돈 버는 앱테크 스픽캐시는 영어를 들으면
                                </Text>
                                <Text size={16} bold>
                                {" 200캐시"}
                                </Text>
                                <Text size={16}>
                                를 드립니다.
                                </Text>
                            </TextLine>
                        </BulletListItem>
                    </BulletList>
                </NoteContainer>
                <NoteContainer>
                    <Text bold size={16}>
                        돈 버는 영어 라디오, 스픽캐시 사용 방법 2가지!
                    </Text>
                    <BulletList>
                        <BulletListItem>
                            <TextLine>
                                <Text size={16}>
                                첫번째,
                                </Text>
                                <Text size={16} bold>
                                {" 재생버튼 "}
                                </Text>
                                <Text size={16}>
                                딱 한번만 누른 후
                                </Text>
                                <Text size={16} bold>
                                {" 백그라운드"}
                                </Text>
                                <Text size={16}>
                                로 보내고 카톡하고 뉴스보고 주식보고 다른 앱테크 미션하면 끝. 매일 200캐시!
                                </Text>
                            </TextLine>
                        </BulletListItem>
                        <BulletListItem>
                            <TextLine>
                                <Text size={16}>
                                두번째,
                                </Text>
                                <Text size={16} bold>
                                {" 재생버튼 "}
                                </Text>
                                <Text size={16}>
                                또 한 번만 누르고 출근 준비, 설거지, 씻을 때, 자면서 그냥 틀어두면 끝. 매일 200캐시!
                                </Text>
                            </TextLine>
                        </BulletListItem>
                    </BulletList>
                </NoteContainer>
                <NoteContainer>
                    <Text bold size={16}>
                        행운 상자 유의사항
                    </Text>
                    <BulletList>
                        <BulletListItem>
                            <Text size={16}>
                            행운 상자를 열고 스픽캐시 가입을 완료해야 당첨 상품이 지급됩니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            이벤트 코드로 가입해야만 당첨 선물이 지급됩니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            초대코드 보상과 중첩되지 않습니다. 이벤트 코드로 가입시 초대 코드를 입력해도 캐시가 지급되지 않습니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            상품이 당첨되면 해당 상품에 해당하는 캐시를 지급해 드리며 캐시 내역 - 이벤트에서 확인이 가능합니다. 해당 캐시로 상품을 구매할 수 있습니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            기존 스픽캐시에 가입한 회원은 이벤트에 참여해도 캐시가 지급되지 않습니다. [전화번호로 구분됩니다.]
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            부적절한 방법에 의한 이벤트 참여가 확인되면 캐시 및 이벤트 혜택 적립이 취소됩니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            부적절한 방법에 의한 이벤트 참여가 확인되면 캐시 및 이벤트 혜택 적립이 취소됩니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            금품 등을 대가로 한 이벤트 코드 거래 시 캐시 회수 및 이용에 제한이 있을 수 있습니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            스픽캐시 이용 약관 및 정책에 의해 만 14세 미만의 사용자인 경우 스픽캐시와 이벤트에 참여할 수 없습니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            해당 이벤트는 당사 사정에 의해 사전 공지 없이 내용이 변경되거나 기간이 조정될 수 있습니다.
                            </Text>
                        </BulletListItem>
                        <BulletListItem>
                            <Text size={16}>
                            해당 이벤트는 구글 혹은 애플 등 타사와 무관하며, 스폰서십 등의 관계가 일절 존재하지 않습니다.
                            </Text>
                        </BulletListItem>
                    </BulletList>
                </NoteContainer>
            </Container>
        </ThemeProvider>
        
    );
}

export default App;
